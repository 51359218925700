export default {
  setUser(state, value) {
    state.user = value;
  },
  setDialog(state, value) {
    state.dialog = value;
  },

  setSnackbar(state, value) {
    state.snackbar = value;
  },
  setDisplayName(state, value) {
    state.user.displayName = value;
  }
};
