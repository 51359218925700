<template>
  <v-menu :close-on-content-click="false" left bottom offset-y :nudge-width="200" data-app>
    <template v-slot:activator="{ on }">
      <v-btn plain :text="text" :color="color" class="mx-0" v-on="on">
        <v-icon>mdi-chevron-down</v-icon>
        <span class="text-truncate">{{ displayName || userFullName || "Guest" }}</span>
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item v-if="isAuthenticated">
          <v-list-item-avatar color="primary">
            <span class="white--text">{{ userInitial }}</span>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-light">
              {{ displayName || userFullName }}
            </v-list-item-title>
            <v-list-item-subtitle class="font-weight-light">
              {{ userName }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-avatar color="primary">
            <span class="white--text">G</span>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-light"> Guest </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <!-- <v-list-item-avatar> -->
          <v-switch v-model="dark" class="mr-2"></v-switch>
          <!-- </v-list-item-avatar> -->
          <v-list-item-title class="font-weight-light"> Dark Theme </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar> </v-list-item-avatar>
          <v-list-item-title class="font-weight-light">
            <v-select v-model="$vuetify.lang.current" :items="supportedLanguages" label="Language"></v-select>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-card-actions class="py-2" :style="{ backgroundColor: $vuetify.theme.themes[theme].background }">
        {{ $store.getters.appVersion }}
        <v-spacer></v-spacer>
        <v-btn v-if="isAuthenticated" small text dark class="testing-logout-button" @click="logout" color="primary">Log Out</v-btn>
        <v-btn v-else small text dark @click="login" color="primary"> Log In </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { loginRequest } from "../auth/authConfig";
import { InteractionType } from "@azure/msal-browser";
import auth from "@/auth/msal-authprovider.js";
import authMixin from "@/mixins/AuthMixin.js";

export default {
  name: "AccountComponent",
  mixins: [authMixin],
  props: {
    text: { type: Boolean },
    initial: { type: Boolean },
    color: { type: String }
  },
  computed: {
    footerColor() {
      return "grey lighten-3";
    },
    dark: {
      get() {
        return this.$vuetify.theme.dark;
      },
      set(value) {
        this.$vuetify.theme.dark = value;
        localStorage.setItem("darkTheme", value.toString());
      }
    },
    supportedLanguages() {
      return Object.keys(this.$vuetify.lang.locales);
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    }
  },
  methods: {
    login() {
      const request = {
        ...loginRequest,
        redirectStartPage: "/home"
      };
      return auth.signIn(InteractionType.Redirect, request);
    },
    logout() {
      auth.logout();
    }
  }
};
</script>
