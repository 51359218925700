<template>
  <v-app>
    <AppBarComponent />
    <v-main>
      <div class="mt-2">
        <router-view v-if="!authenticating"></router-view>
        <div v-else class="d-flex justify-center">
          <v-card outlined min-width="300">
            <v-card-text>
              <p class="text-center">Authenticating...</p>
              <v-progress-linear indeterminate color="primary"></v-progress-linear>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-main>
    <v-snackbar v-model="snackbar.show" :color="snackbar.backgroundColor" :timeout="snackbar.timeout">
      <div style="overflow-wrap: anywhere">{{ snackbar.text }}</div>
      <template v-if="snackbar.actionHandler" v-slot:action="{ attrs }">
        <v-btn :color="snackbar.color" text v-bind="attrs" @click="snackbar.actionHandler"> {{ snackbar.actionText }} </v-btn>
      </template>
      <template v-else v-slot:action="{ attrs }">
        <v-btn :color="snackbar.color" text v-bind="attrs" @click="hideSnackbar"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import AppBarComponent from "./components/AppBar";
import { EventBus } from "@/event/eventBus.js";

export default {
  name: "App",
  components: {
    AppBarComponent
  },
  data: () => ({
    authenticating: false
  }),
  computed: {
    snackbar() {
      return this.$store.state.snackbar;
    }
  },
  methods: {
    hideSnackbar() {
      this.$store.commit("setSnackbar", { show: false });
    }
  },
  created() {
    this.$vuetify.theme.dark = localStorage.getItem("darkTheme") === "true";
    EventBus.$on("auth-started", () => {
      this.authenticating = true;
    });
    EventBus.$on("auth-finished", () => {
      this.authenticating = false;
    });
  }
};
</script>
