import { logger } from "@/util/logger.js";

export default class Fetcher {
  constructor(getTokenFunction) {
    this.aquireToken = getTokenFunction
      ? getTokenFunction
      : () => {
          return Promise.resolve(null);
        };

    this.mode = "cors";
    this.contentType = "application/json";
  }

  getJson(request, path, method, data, contentType, rawResponse) {
    var endpoint = request && request.endpoint ? request.endpoint : "";
    var url = path ? endpoint.concat("/").concat(path ? path : "") : endpoint;
    var opts = request ? request.options : null;
    logger.log(method, url, data);
    var t = this;
    var contentTyp = contentType !== undefined ? contentType : t.contentType;
    return this.aquireToken(opts)
      .then(token => {
        var headers = {};
        if (contentTyp) {
          headers["Content-Type"] = contentTyp;
        }

        if (token && token.length > 0) {
          headers.Authorization = `Bearer ${token}`;
        }

        var dat = null;

        if (data) {
          dat = contentTyp?.toLowerCase() === "application/json" ? JSON.stringify(data) : data;
        }

        return fetch(encodeURI(url), {
          method: method || "GET",
          mode: t.mode,
          body: dat,
          headers: new Headers(headers)
        });
      })
      .then(async r => {
        if (rawResponse) return r;
        var text = await r.text();
        try {
          text = JSON.parse(text);
          r.isJson = true;
        } catch {
          r.isJson = false;
        }

        if (r.ok) return text;
        else {
          if (r.isJson && text.errors && Object.values(text.errors)?.length > 0) {
            r.customError = Object.values(text.errors)
              .map(e => e.join(", "))
              .join(", ");
          } else if (text || (!r.isJson && text.length > 0)) r.customError = text;
          else if (r.statusText) r.customError = r.statusText;
          throw r;
        }
      });
  }
}
