export default {
  version: `v${process.env.VUE_APP_VERSION || "0"}`,
  user: null,
  snackbar: {
    show: false,
    text: null,
    color: "primary",
    backgroundColor: null,
    timeout: null,
    actionText: null,
    actionHandler: null
  },
  dialog: {
    show: false,
    title: null,
    text: null,
    color: "primary"
  }
};
