//import api from "api-client";
export default {
  showDialog(context, opts) {
    var d = {
      title: opts.title || null,
      text: opts.text || null,
      color: opts.color || "primary",
      width: opts.width || null,
      show: true
    };
    context.commit("setDialog", d);
  },
  showSnackbar(context, opts) {
    var s = {
      text: opts.text || null,
      color: opts.color || "primary",
      backgroundColor: opts.backgroundColor || null,
      timeout: opts.timeout || null,
      show: true,
      actionText: opts.actionText || null,
      actionHandler: opts.actionHandler || null
    };
    context.commit("setSnackbar", s);
  },
  copyTextToClipboard({ dispatch }, txt) {
    navigator.clipboard.writeText(txt).then(
      function () {
        /* clipboard successfully set */
        dispatch("showSnackbar", {
          text: `${txt} copied to clipboard`,
          timeout: 3000
        });
      },
      function () {
        /* clipboard write failed */
        dispatch("showSnackbar", {
          text: `unable to copy to clipboard`,
          timeout: 3000
        });
      }
    );
  },
  setDisplayName(context, value) {
    context.commit("setDisplayName", value);
  }
};
