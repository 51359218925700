<template>
  <v-app-bar app class="gradient-bar" dark>
    <v-img :src="`${cdnUrl}/cdn/gm-cvep/logo.png`" max-height="50" max-width="50" class="mr-4"></v-img>
    <v-toolbar-title>
      <span v-if="isMobile">CVEP</span>
      <span v-else>Company Vehicle Evaluation Program</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <AccountComponent />
    <template v-slot:extension>
      <v-tabs v-if="isAuthenticated" center-active :centered="isMobile" :key="isMobile" color="white">
        <v-tab to="/home"> <v-icon class="mr-1">mdi-home</v-icon> <span v-if="!isMobile">Home</span> </v-tab>
        <v-tab v-if="isProblemSolver" to="/problem-solvers">
          <v-icon class="mr-1">mdi-chat</v-icon> <span v-if="!isMobile">Problem Solvers</span>
        </v-tab>
        <v-tab to="/profile"> <v-icon class="mr-1">mdi-account-box</v-icon> <span v-if="!isMobile">Profile</span></v-tab>
        <v-tab to="/help"> <v-icon class="mr-1">mdi-help-circle</v-icon> <span v-if="!isMobile">Help</span></v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<style scoped>
.gradient-bar {
  background: linear-gradient(to top right, rgba(0, 186, 246, 1), rgba(63, 83, 200, 0.8));
}
</style>

<script>
import authMixin from "@/mixins/AuthMixin.js";
import AccountComponent from "./Account.vue";
export default {
  name: "AppBarComponent",
  mixins: [authMixin],
  components: {
    AccountComponent
  },
  methods: {},
  computed: {
    cdnUrl() {
      return process.env.VUE_APP_CDN_URL;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    }
  }
};
</script>
