import auth from "@/auth/msal-authprovider.js";
export default {
  name: "authMixin",

  data: () => ({}),
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isAdmin() {
      return this.isInRole("ADMIN");
    },
    isProblemSolver() {
      return this.isAdmin || this.isInRole("CVEP_PROBLEM_SOLVER");
    },
    isCvepUser() {
      return this.isAdmin || this.isInRole("CVEP_USER");
    },

    authedUser() {
      return auth.user;
    },
    userInitial() {
      return (this.displayName || this.userName)?.substring(0, 1)?.toUpperCase();
    },
    userFullName() {
      return auth?.getActiveAccount()?.name;
    },
    userName() {
      return auth?.getActiveAccount()?.username;
    },
    displayName() {
      return this.$store.state.user?.displayName;
    },
    assignmentType() {
      return this.$store.state.user?.assignmentType;
    }
  },
  methods: {
    isInRole(requiredRole) {
      return this.$store.state.user?.roles?.includes(requiredRole) === true;
    },
    hasAnyRoles() {
      var args = Array.prototype.slice.call(arguments);
      return this.$store.state.user?.roles?.some(r => args.includes(r)) === true;
    },
    hasAllRoles() {
      var args = Array.prototype.slice.call(arguments);
      return args.every(this.isInRole);
    }
  }
};
