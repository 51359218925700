import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes.js";
import { registerGuard } from "./guard.js";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

if (process.env.NODE_ENV !== "test") registerGuard(router);

export default router;
