import { logger } from "@/util/logger.js";
import { EventMessageUtils, EventType, InteractionStatus, InteractionType } from "@azure/msal-browser";
import { msalInstance } from "./authConfig";
import store from "../store";
import api from "api-client";

class MSALAuthProvider {
  constructor() {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    this.state = {
      instance: msalInstance,
      inProgress: InteractionStatus.Startup,
      accounts: accounts
    };

    var t = this;
    msalInstance.addEventCallback(message => {
      const status = EventMessageUtils.getInteractionStatusFromEvent(message, this.state.inProgress);
      logger.log("MSAL: getInteractionStatusFromEvent", status);
      if (status !== null) {
        this.state.inProgress = status;
      }
      if (message.eventType === EventType.LOGIN_SUCCESS && message.payload) {
        const payload = message.payload;
        const account = payload.account;
        logger.log("MSAL: settting active account", status);
        t.setAccount(account);
      }
    });
  }
  getActiveAccount() {
    return msalInstance.getActiveAccount();
  }
  isAuthenticated() {
    logger.log("MSAL: checking authenticated");
    var accounts = msalInstance.getAllAccounts();
    return accounts?.length > 0;
  }
  logout() {
    logger.log("MSAL: logging out");
    this.user = null;
    msalInstance.logoutRedirect();
    this.setAccount(null);
  }

  async getTokenPopup(request) {
    logger.log("MSAL: getTokenPopup");
    await msalInstance.initialize();
    var r = await msalInstance.handleRedirectPromise();
    if (r) msalInstance.setActiveAccount(r.account);

    return msalInstance
      .acquireTokenSilent(request)
      .then(r => {
        logger.log("MSAL: Aquired token silently");
        return r;
      })
      .catch(() => {
        logger.log("MSAL: silent token acquisition fails. acquiring token using popup");
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenPopup(request).then(r => {
          logger.log("MSAL: token aquired");
          return r;
        });
      })
      .then(token => {
        logger.log("MSAL: returning token");
        //logger.log(request.scopes, token);
        return token;
      });
  }

  async getTokenRedirect(request) {
    logger.log("MSAL: getTokenRedirect");
    await msalInstance.initialize();
    var r = await msalInstance.handleRedirectPromise();
    if (r) msalInstance.setActiveAccount(r.account);

    return msalInstance
      .acquireTokenSilent(request)
      .then(r => {
        logger.log("MSAL: Aquired token silently");
        return r;
      })
      .catch(ex => {
        logger.log("MSAL: silent token acquisition fails. acquiring token using redirect", ex);
        // fallback to interaction when silent call fails
        return msalInstance
          .acquireTokenRedirect(request)
          .then(r => {
            logger.log("MSAL: token aquired via redirect");
            return r;
          })
          .catch(ex => {
            logger.log("MSAL: unable to acquire token using redirect", ex);
          });
      })
      .then(token => {
        logger.log("MSAL: returning token");
        //logger.log(request.scopes, token);
        return token;
      });
  }

  async setAccount(account) {
    logger.log("MSAL: setAuth");
    msalInstance.setActiveAccount(account);
    if (!account)
      return {
        success: false,
        message: null
      };

    if (account && account.localAccountId !== this.user?.id) {
      var user = account
        ? {
            id: account.localAccountId,
            name: account.name,
            userName: account.username,
            roles: [],
            assignmentType: null,
            displayName: null
          }
        : null;

      logger.log("MSAL: getting current user roles");
      var getUserResult = await api
        .getCurrentUser()
        .then(r => {
          logger.log("MSAL: got current user roles");
          user.roles = r.roles;
          user.assignmentType = r.assignmentType;
          user.displayName = r.displayName;
          this.user = user;
          store.commit("setUser", user);
          return {
            success: true,
            message: null
          };
        })
        .catch(ex => {
          this.user = null;
          store.commit("setUser", null);
          logger.log(ex);
          var msg = ex?.customError || "Invalid Login";

          return {
            success: false,
            message: msg
          };
        });
      return getUserResult;
    }
    return {
      success: true,
      message: null
    };
  }

  /* eslint-disable-next-line */
  async signIn(interactionType, loginReq) {
    logger.log("MSAL: signIn");

    var t = this;
    await msalInstance.initialize();

    return msalInstance.handleRedirectPromise().then(async r => {
      if (r !== null) {
        return t.setAccount(r.account);
      } else {
        if (interactionType === InteractionType.Popup) {
          try {
            await msalInstance.loginPopup(loginReq);
            logger.log("MSAL:login popup success");
            return {
              success: true,
              message: null
            };
          } catch {
            logger.log("MSAL:login popup failed");
            return {
              success: false,
              message: null
            };
          }
        } else if (interactionType === InteractionType.Redirect) {
          try {
            await msalInstance.loginRedirect(loginReq);
            logger.log("MSAL:login redirect success");
            return {
              success: true,
              message: null
            };
          } catch (ex) {
            logger.log("MSAL:login redirect failed" + ex);
            return {
              success: false,
              message: null
            };
          }
        } else throw new Error("MSAL: unknown interaction type");
      }
    });
  }
}

export default new MSALAuthProvider();
