class Logger {
  shouldLog() {
    return process.env.VUE_APP_LOGGER === "on" && process.env.NODE_ENV === "development";
  }
  log() {
    if (this.shouldLog()) {
      var args = Array.prototype.slice.call(arguments);
      // eslint-disable-next-line no-console
      console.log.apply(console, args);
    }
  }
  info() {
    if (this.shouldLog()) {
      var args = Array.prototype.slice.call(arguments);
      // eslint-disable-next-line no-console
      console.info.apply(console, args);
    }
  }
  warn() {
    if (this.shouldLog()) {
      var args = Array.prototype.slice.call(arguments);
      // eslint-disable-next-line no-console
      console.info.apply(console, args);
    }
  }
  debug() {
    if (this.shouldLog()) {
      var args = Array.prototype.slice.call(arguments);
      // eslint-disable-next-line no-console
      console.debug.apply(console, args);
    }
  }
  error() {
    if (this.shouldLog()) {
      var args = Array.prototype.slice.call(arguments);
      // eslint-disable-next-line no-console
      console.error.apply(console, args);
    }
  }
}

exports.logger = new Logger();
